import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import LandingView from "./LandingView";
import SpectatorView from "./SpectatorView";
import { Jeopardy } from "../../jeopardy/Jeopardy";
import { QuizApp } from "../../quiz/Quiz";
import { TemplatesListView } from "./TemplatesListView";
import { SettingsViewJeopardy } from "./SettingsViewJeopardy";
import { SettingsViewQuiz } from "./SettingsViewQuiz";

const PrivateView: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingView} />
      <Route path="/spectate" component={SpectatorView} />
      <Route path="/templates/:type" component={TemplatesListView} />
      <Route path="/jeopardy" component={Jeopardy} />
      <Route path="/quiz" component={QuizApp} />
      <Route path="/settings-jeopardy" component={SettingsViewJeopardy} />
      <Route path="/settings-quiz" component={SettingsViewQuiz} />

      <Redirect to="/" />
    </Switch>
  );
};

export default PrivateView;
