import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LoginView } from "./LoginView";

export const PublicView: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <LoginView />} />

      <Redirect to="/" />
    </Switch>
  );
};
