import React, { useState } from "react";
import { Redirect } from "react-router";

import { Game, gameConverter, updateCategory, deleteCategory } from "../model/Game";
import { useHistory, useParams } from "react-router-dom";
import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";

import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { LoadingView } from "../../views/LoadingView";
import { Container } from "../../components/Container";
import { Title, Text } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { StyledButton } from "../../components/Buttons";

interface RouteParams {
  categoryId: string;
}

export const EditCategoryView: React.FC<{ game: Game }> = ({ game }) => {
  console.log("EditCategoryView");
  const auth = getAuth();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const ref = doc(getFirestore(), "templates", game.id).withConverter(gameConverter);

  const { mutate, isLoading, isSuccess } = useFirestoreDocumentMutation(ref);
  const { data: userData, isLoading: userIsLoading } = useAuthUser(["user"], auth);
  const categoryId = Number(params.categoryId);
  const category = game.categories[categoryId];
  const [categoryName, setCategoryName] = useState((category && category.name) || "");

  if (userIsLoading || isLoading) {
    return <LoadingView />;
  }

  if (!userData) {
    return <Redirect to="/" />;
  }

  if (!category) {
    return <Redirect to={`/jeopardy/game/${game.id}`} />;
  }

  const handleDeleteCategory = async () => {
    await mutate(deleteCategory(game, categoryId));
  };

  if (isSuccess) {
    return <Redirect to={`/jeopardy/game/${game.id}`} />;
  }

  return (
    <form onSubmit={() => mutate(updateCategory(game, categoryId, categoryName))}>
      <Container>
        <Title>{"Edit category"}</Title>
        <StyledInput
          value={""}
          required
          onChange={(e) => setCategoryName(e.target.value)}
          minLength={1}
          autoFocus
        ></StyledInput>
        <StyledButton variant="green" type="submit" disabled={isLoading}>
          <Text color="white">{"UPDATE CATEGORY"}</Text>
        </StyledButton>
        <StyledButton onClick={() => handleDeleteCategory()} variant="brown" type="button" disabled={isLoading}>
          <Text color="white">{"DELETE CATEGORY"}</Text>
        </StyledButton>
        <StyledButton
          onClick={() => {
            history.replace(`/jeopardy/game/${game.id}`);
          }}
        >
          <Text color="white">{"BACK"}</Text>
        </StyledButton>
      </Container>
    </form>
  );
};
