import React from "react";
import { Container } from "../../components/Container";
import { StyledButton } from "../../components/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import { Title, Text } from "../../components/Typography";
import { StyledRow } from "../../components/StyledRows";
import { StyledToggle } from "../../components/StyledToggle";
import { Footer } from "../../components/Footer";
import { Room } from "../../jeopardy/model/Room";

export const SettingsViewJeopardy: React.FC = () => {
  //console.log("SettingsViewJeopardy");
  const history = useHistory();
  const room = useLocation<{ room: Room }>().state.room;
  //console.log(room);

  return (
    <Container>
      <Title color="white">{"Jeopardy game settings"}</Title>
      <StyledRow>
        <Text fontSize={1.5}>{"All can answer jeopardy questions"}</Text>
        <StyledToggle room={room} settingName="allPlayersCanAnswer" />
      </StyledRow>
      <Footer>
        <StyledButton onClick={() => history.goBack()}>
          <Text color="white">{"BACK"}</Text>
        </StyledButton>
      </Footer>
    </Container>
  );
};
