import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  // jeopardy font
  @font-face {
    font-family: "heebo";
    src: url("/fonts/heebo.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "monofont";
    src: url("/fonts/monofont.ttf");
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  html {
    height: 100%;
	font-family: "Amatic SC", cursive;
	font-weight: 400;
  	padding: 0;
	margin: 0;
  }

  #root {
    height: 100%;
    animation: fadeIn 1000ms;
  }

  @keyframes fadeIn { 
    from { opacity: 0; }
	to { opacity: 1; }
  }
`;
