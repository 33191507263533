import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { collection } from "firebase/firestore";
import { db } from "../../services/firebase.service";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { createGame, gameConverter } from "../model/Game";
import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { LoadingView } from "../../views/LoadingView";
import { GameType } from "../../constants";

import { Container } from "../../components/Container";
import { Title, Text } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { StyledButton } from "../../components/Buttons";

export const CreateGameView: React.FC<RouteComponentProps> = ({ history }) => {
  //console.log("CreateGameView");
  const ref = collection(db, "templates").withConverter(gameConverter);
  const { mutate, data, isLoading } = useFirestoreCollectionMutation(ref);
  const auth = getAuth();
  const { data: userData, isLoading: userIsLoading } = useAuthUser(["user"], auth);
  const [name, setName] = useState("");

  if (userIsLoading || isLoading) {
    return <LoadingView />;
  }

  if (!userData) {
    return <Redirect to="/" />;
  }

  if (data) {
    return <Redirect push to={`/quiz/game/${data.id}`} />;
  }

  return (
    <form onSubmit={() => mutate(createGame(name, userData.uid, GameType.QUIZ))}>
      <Container>
        <Title>Enter game name</Title>
        <StyledInput
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
          minLength={4}
          autoFocus
        ></StyledInput>
        <StyledButton type="submit" variant="brown" disabled={isLoading}>
          <Text color="white">{"CREATE GAME"}</Text>
        </StyledButton>
        <StyledButton
          onClick={() => {
            history.replace(`/templates/${GameType.QUIZ}`);
          }}
        >
          <Text color="white">{"BACK"}</Text>
        </StyledButton>
      </Container>
    </form>
  );
};
