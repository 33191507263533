import React, { useState } from "react";

import { MainHeader } from "../components/MainHeader";
import { HeaderRowOne, HeaderRowTwo } from "../../components/StyledRows";

import { CategoryIcon, PlusIcon } from "../../components/Icon";
import { GameCell } from "../components/GameCell";
import { CategoryItem } from "../components/CategoryItem";
import { Container } from "../../components/Container";

import { styled } from "@mui/material";
import { Redirect, useHistory } from "react-router-dom";
import { Game } from "../model/Game";
import { useFirestoreCollectionMutation, useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";
import { createRoom, getRoomCollectionRef } from "../model/Room";
import { collection, doc, getFirestore } from "firebase/firestore";
import { useUser } from "../../hooks/useUser";
import { StyledButton, StyledHeaderButton } from "../../components/Buttons";
import { Text } from "../../components/Typography";
import { LoadingView } from "../../views/LoadingView";
import { areStringFieldsEmpty } from "../../util";
import { GameGrid } from "../components/GameGrid";

interface Props {
  game: Game;
}

export const EditGameView: React.FC<Props> = ({ game }) => {
  console.log("EditGameView");
  const history = useHistory();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { mutate, data } = useFirestoreCollectionMutation(getRoomCollectionRef());
  const ref = doc(collection(getFirestore(), "templates"), game.id);
  const { mutate: deleteGame } = useFirestoreDocumentDeletion(ref);

  function createRoomHandler() {
    setLoading(true);

    mutate(createRoom(game, user.uid));
  }

  function deleteHandler() {
    setLoading(true);

    deleteGame();
    history.replace("/");
  }

  if (data) {
    return <Redirect to={`/jeopardy/room/${data.id}`} />;
  }

  if (loading) {
    return <LoadingView />;
  }

  return (
    <Container>
      <MainHeader directionVariant="column">
        <HeaderRowOne justifyContentVariant="space-evenly">
          <StyledHeaderButton onClick={() => history.replace(`/templates/${game.type}`)}>
            <Text color="white" fontSize={1.1}>
              {"Back"}
            </Text>
          </StyledHeaderButton>
          <StyledHeaderButton onClick={deleteHandler}>
            <Text color="white" fontSize={1.1}>
              {"Delete jeopardy"}
            </Text>
          </StyledHeaderButton>
          <StyledHeaderButton onClick={createRoomHandler}>
            <Text color="white" fontSize={1.1}>
              {"Start jeopardy"}
            </Text>
          </StyledHeaderButton>
        </HeaderRowOne>
        <HeaderRowTwo>
          <Text color="white">{game.name.toUpperCase()}</Text>
        </HeaderRowTwo>
      </MainHeader>

      <GameGrid columnCount={game.categories.length === 5 ? game.categories.length : game.categories.length + 1}>
        {game.categories.map((category, categoryIndex) => (
          <GameCell backPaint="black" key={category.id}>
            <CategoryItem
              titleName
              categoryCount={100 / game.categories.length}
              onClick={() => history.push(`/jeopardy/game/${game.id}/edit-category/${category.id}`)}
            >
              <Text>{category.name}</Text>
            </CategoryItem>
            {category.questions?.map((question, questionIndex) => {
              const isFilled = areStringFieldsEmpty(question);
              return (
                <CategoryItem
                  questionFilled={isFilled}
                  key={question.id}
                  onClick={() =>
                    history.push(`/jeopardy/game/${game.id}/edit-question/${categoryIndex}/${questionIndex}`)
                  }
                >
                  {question.value}
                </CategoryItem>
              );
            })}
          </GameCell>
        ))}
        {game.categories.length > 0 && game.categories.length < 5 ? (
          <GameCell plusIcon onClick={() => history.push(`/jeopardy/game/${game.id}/create-category`)}>
            <PlusIcon />
          </GameCell>
        ) : null}
        {game.categories.length === 0 ? (
          <GameCell>
            <Container>
              <Text fontSize={2}>Create your first category</Text>
              <StyledCategoryIcon />
              <StyledButton
                title="Click to create the first category"
                onClick={() => history.push(`/jeopardy/game/${game.id}/create-category`)}
              >
                <Text color="white">{"CREATE CATEGORY"}</Text>
              </StyledButton>
            </Container>
          </GameCell>
        ) : null}
      </GameGrid>
    </Container>
  );
};

const StyledCategoryIcon = styled(CategoryIcon)`
  margin: 2em 0;

  @media screen and (orientation: landscape) {
    margin: 1em 0;
  }
`;
