import React from "react";
import { RoomUser } from "../hooks/useRoomUser";
import { Room as JeopardyRoom } from "../jeopardy/model/Room";
import { Room as QuizRoom } from "../quiz/model/Room";
import { Text, GameName } from "../components/Typography";

import { Answer, Question } from "../types";

import { AnswerRow, NameRow, ScoreRow, AnswerListRow } from "./StyledRows";
import { AnswersContainer } from "./AnswersContainer";

export type OnRateAnswer = (id: string, isCorrect: boolean) => Promise<void> | void;

interface AnswerListProps {
  question: Question;
  onRateAnswer?: OnRateAnswer;
  user: RoomUser;
  room: JeopardyRoom | QuizRoom;
}

const AnswerList: React.FC<AnswerListProps> = ({ question, onRateAnswer, user, room }) => {
  return (
    <AnswersContainer>
      {question.answers.length === 0 ? (
        <Text color="white">{"No answers yet"}</Text>
      ) : (
        <Text color="white">{"Answers"}</Text>
      )}
      {renderAnswers(question)}
    </AnswersContainer>
  );

  async function answerClicked(answer: Answer) {
    await onRateAnswer?.(answer.id, !answer.isCorrect);
  }

  function renderAnswers(question: Question) {
    if (question.answers.length === 0) {
      return null;
    }

    return (
      <AnswersContainer>
        {question.answers.map((answer) => (
          <>
            <AnswerListRow key={answer.id}>
              <NameRow>
                <GameName color="white">{answer.authorName}</GameName>
              </NameRow>
              <ScoreRow>
                <GameName color="white">
                  {canDisplayUserAnswerResult({ answer, user, room }) ? answer.points : "***"}
                </GameName>
              </ScoreRow>
              <AnswerRow answerCorrect={answer.isCorrect} onClick={() => (user.isAdmin ? answerClicked(answer) : null)}>
                <GameName color={answer.isCorrect === null ? "white" : answer.isCorrect ? "green" : "red"}>
                  {canDisplayUserAnswerResult({ answer, user, room }) ? (
                    answer.answer
                  ) : (
                    <Text color="white">{"********"}</Text>
                  )}
                </GameName>
              </AnswerRow>
            </AnswerListRow>
          </>
        ))}
      </AnswersContainer>

      // <Table size="small" sx={{ "td, th": { padding: "6px" } }}>
      //   <TableHead>
      //     <TableRow>
      //       <TableCell>Name</TableCell>
      //       <TableCell>Answer</TableCell>
      //       <TableCell align="right">Score</TableCell>
      //       <TableCell />
      //     </TableRow>
      //   </TableHead>
      //   <TableBody>
      //     {question.answers.map((answer) => (
      //       <TableRow key={answer.id}>
      //         <TableCell>{answer.authorName}</TableCell>
      //         <TableCell>
      //           {canDisplayUserAnswerResult({ answer, user, room })
      //             ? answer.answer
      //             : "********"}
      //         </TableCell>
      //         <TableCell align="right">
      //           {canDisplayUserAnswerResult({ answer, user, room })
      //             ? answer.points
      //             : "***"}
      //         </TableCell>
      //         {user.isAdmin ||
      //         user.uid === answer.authorId ||
      //         (user.isSpectator &&
      //           room.questions[room.activeQuestionId].isAnswered) ? (
      //           <TableCell>{renderActions(answer)}</TableCell>
      //         ) : null}
      //       </TableRow>
      //     ))}
      //   </TableBody>
      // </Table>
    );
  }

  function canDisplayUserAnswerResult(params: { answer: Answer; user: RoomUser; room: JeopardyRoom | QuizRoom }) {
    const { answer, user, room } = params;

    if (user.uid === answer.authorId || user.isAdmin || room.questions[room.activeQuestionId].isAnswered) {
      return true;
    }

    return false;
  }
};

export default AnswerList;
