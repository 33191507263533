import { getAuth } from "firebase/auth";
import { useAuthSignOut, useAuthUser } from "@react-query-firebase/auth";

const auth = getAuth();

export function useUser() {
  const { data: user } = useOptionalUser();

  if (!user) {
    throw new Error("Missing data");
  }

  return user;
}

export function useOptionalUser() {
  return useAuthUser(["user"], auth);
}

export function useLogout() {
  const { mutate, ...rest } = useAuthSignOut(auth);

  return {
    logout: mutate,
    ...rest,
  };
}
