/* eslint-disable */

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
};

export type Answer = {
  __typename?: "Answer";
  answer?: Maybe<Scalars["String"]>;
  authorId: Scalars["ID"];
  authorName: Scalars["String"];
  id: Scalars["ID"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
  points?: Maybe<Scalars["Int"]>;
  status: AnswerStatus;
};

export enum AnswerStatus {
  Answered = "ANSWERED",
  CanAnswer = "CAN_ANSWER",
}

export type Player = {
  __typename?: "Player";
  id: Scalars["ID"];
  name: Scalars["String"];
  points: Scalars["Int"];
};

export type Question = {
  __typename?: "Question";
  answers: Array<Answer>;
  clientStatus?: Maybe<QuestionStatus>;
  hints?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  points: Scalars["Int"];
  question?: Maybe<Scalars["String"]>;
  status: QuestionStatus;
  ttlSec?: Maybe<Scalars["Int"]>;
  validUntil?: Maybe<Scalars["DateTime"]>;
};

export enum QuestionStatus {
  CanAnswer = "CAN_ANSWER",
  Created = "CREATED",
  Locked = "LOCKED",
}

export type User = {
  id: string;
  name: string;
};
