import React from "react";
import { Route, Switch } from "react-router";

import { CreateCategoryView } from "./CreateCategoryView";
import { EditGameView } from "./EditGameViewJeopardy";
import { EditQuestionView } from "./EditQuestionViewJeopardy";
import { Destination, ErrorView } from "../../views/ErrorView";
import { LoadingView } from "../../views/LoadingView";
import { useParams, useRouteMatch } from "react-router-dom";
import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { gameConverter } from "../model/Game";
import { EditCategoryView } from "./EditCategoryViewJeopardy";

interface RouteParams {
  gameId: string;
}

export const GameView: React.FC = () => {
  //console.log("GameView");
  const { gameId } = useParams<RouteParams>();
  const match = useRouteMatch();

  const ref = doc(getFirestore(), "templates", gameId).withConverter(gameConverter);
  const { isLoading, data, error } = useFirestoreDocument(["templates", gameId], ref, { subscribe: true });

  if (isLoading) {
    return <LoadingView />;
  }

  const game = error ? null : data?.data();

  if (error || !game) {
    return <ErrorView error={error || new Error("Game not found")} destination={Destination.JEOPARDY} />;
  }

  return (
    <Switch>
      <Route path={`${match.url}/create-category`}>
        <CreateCategoryView game={game} />
      </Route>
      <Route path={`${match.url}/edit-category/:categoryId`}>
        <EditCategoryView game={game} />
      </Route>
      <Route path={`${match.url}/edit-question/:categoryId/:questionId`}>
        <EditQuestionView game={game} />
      </Route>
      <Route path={`${match.url}`} exact={true}>
        <EditGameView game={game} />
      </Route>
    </Switch>
  );
};
