import React from "react";
import styled from "styled-components";

import { icon, theme } from "../views/theme";

export interface IconConfiguration {
  width?: string;
  height?: string;
  color?: string;
}

export type IconProps = {
  filename: string;
} & IconConfiguration;

export const Icon = styled.i<IconProps>`
  display: inline-block;
  width: ${(props) => props.width || props.theme.size.icon};
  height: ${(props) => props.height || props.theme.size.icon};
  ${(props) => icon(props.filename, props.width, props.height, props.color)}
`;

export const BackIcon: React.SFC<IconConfiguration> = (props) => (
  <Icon filename="back.svg" color={theme.text.tertiary} {...props} />
);
export const ForwardIcon: React.SFC<IconConfiguration> = (props) => (
  <Icon filename="forward.svg" color={theme.text.tertiary} {...props} />
);

export const PlusIcon: React.SFC<IconConfiguration> = (props) => (
  <Icon filename="create-category.svg" color={theme.text.tertiary} width="3em" height="3em" {...props} />
);

export const LoadingIcon: React.SFC<IconConfiguration> = (props) => (
  <Icon width="3em" height="3em" filename="loading.svg" {...props} />
);

export const CategoryIcon: React.SFC<IconConfiguration> = (props) => (
  <Icon width="5em" height="5em" filename="category.svg" {...props} />
);

export const Logo: React.SFC<IconConfiguration> = (props) => (
  <Icon color={theme.bg.primary} width="3em" height="3em" filename="logo.svg" {...props} />
);
