import React from "react";
import { Container } from "../../components/Container";
import { StyledButton } from "../../components/Buttons";
import { useHistory } from "react-router-dom";
import { Title, Text } from "../../components/Typography";
import { Footer } from "../../components/Footer";

export const SettingsViewQuiz: React.FC = () => {
    const history = useHistory();
    
    return (
        <Container>
            <Title color="white">{"Quiz game settings"}</Title>
            <Footer>
                <StyledButton
                    onClick={() => history.push(`/settings`)}
                >
                    <Text color="white">{"BACK"}</Text>
                </StyledButton>
            </Footer>
        </Container>
    );
};
