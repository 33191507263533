import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { Text } from "../components/Typography";
import { Container } from "../components/Container";
import { MainHeader } from "../jeopardy/components/MainHeader";
import { StyledButton } from "../components/Buttons";

export enum Destination {
  "JEOPARDY" = "/jeopardy",
  "QUIZ" = "/quiz",
}

export interface Props extends RouteComponentProps {
  error: Error | undefined;
  destination: Destination;
}

export const ErrorView = withRouter<Props, React.ComponentType<Props>>(({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Container>
      <MainHeader>
        <Text fontSize={2} color="white">
          {"OOPS! ERROR OCCURRED"}
        </Text>
      </MainHeader>

      <Text fontSize={2}>{"The game encountered an error"}</Text>
      <Text>Error: {error.message}</Text>

      <StyledButton variant="green" onClick={() => (window.location.href = "/")}>
        <Text color="white">{"START OVER"}</Text>
      </StyledButton>

      <StyledButton onClick={() => window.location.reload()}>
        <Text color="white">{"RELOAD PAGE"}</Text>
      </StyledButton>
    </Container>
  );
});
