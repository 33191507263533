import styled from "styled-components";

export type ButtonVariant = "default" | "green" | "brown" | "gray";

export interface ButtonProps {
  variant?: ButtonVariant;
}

type Colors = Record<ButtonVariant, string>;

const bgColors: Colors = {
  default: "#556375",
  brown: "#885933",
  green: "#436a6d",
  gray: "#3c3e42",
};

const hoverColor: Colors = {
  default: "#677484",
  brown: "#946a48",
  green: "#578487",
  gray: "#3c3e42",
};

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  font-family: inherit;
  background-color: ${(props) => bgColors[props.variant || "default"]};
  color: white;
  width: 80vw;
  max-width: 30rem;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1.3rem;
  margin: 0.3em;
  border-radius: 4px;
  cursor: pointer;

  &:active,
  &:hover {
    background-color: ${(props) => hoverColor[props.variant || "default"]};
  }
  &:disabled {
    cursor: auto;
  }
`;

export const StyledHeaderButton = styled(StyledButton)`
  width: 6rem;
  height: 2rem;
`;

export const NakedButton = styled.button`
  display: flex;
  font-family: inherit;
  background-color: transparent;
  color: #272727;
  width: 80vw;
  max-width: 30rem;
  border: none;
  font-size: 1.3rem;
  margin: 1em 0 0 0;
  border-radius: 4px;
  cursor: pointer;
  &:active,
  &:hover {
    color: #677484;
  }
`;
