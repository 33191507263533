import styled from "styled-components";

export interface GameCellProps {
  plusIcon?: boolean;
  question?: boolean;
  backPaint?: string;
}

export const GameCell = styled.div<GameCellProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${(props) => (props.question ? "50vh" : "")};
  justify-content: ${(props) => (props.plusIcon ? "center" : "flex-start")};
  align-items: ${(props) => (props.plusIcon ? "center" : "")};
  z-index: 1;
  background-color: ${(props) => (props.backPaint ? props.backPaint : "transparent")};
`;
