import React from "react";

import Error from "./components/Error";
import PrivateView from "./views/private/PrivateView";
import { PublicView } from "./views/public/PublicView";
import { useOptionalUser } from "./hooks/useUser";
import { Container } from "./components/Container";
import { Text } from "./components/Typography";

const App: React.FC = () => {
  const { data, error, isLoading } = useOptionalUser();

  if (error) {
    return (
      <Container variant="center">
        <Error error={error} />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container variant="center">
        <Text>Loading...</Text>
      </Container>
    );
  }

  return data ? <PrivateView /> : <PublicView />;
};

export default App;
