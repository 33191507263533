import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { GameType } from "../../constants";

export interface Question {
  id: number;
  question: string;
  answer: string;
  value: number;
  imageUrl: string;
  answerImageUrl: string;
}

export interface Category {
  id: number;
  name: string;
  questions: Question[];
}

export interface Game {
  id: string;
  creatorId: string;
  name: string;
  categories: Category[];
  type: GameType;
}

export function createGame(name: string, creatorId: string, type: GameType): Game {
  return {
    id: "temp",
    creatorId,
    name,
    categories: [],
    type,
  };
}

export function addCategory(game: Game, name: string): Game {
  return {
    ...game,
    categories: [...game.categories, createCategory(game.categories.length, name)],
  };
}

function createCategory(id: number, name: string): Category {
  const questions: Question[] = [];

  for (let i = 1; i <= 5; i++) {
    questions.push({
      id: i - 1,
      question: "",
      answer: "",
      value: i * 100,
      imageUrl: "",
      answerImageUrl: "",
    });
  }

  return {
    id,
    name,
    questions,
  };
}

export function updateCategory(game: Game, categoryId: number, name: string): Game {
  return {
    ...game,
    categories: game.categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          name,
        };
      }

      return category;
    }),
  };
}

export function deleteCategory(game: Game, categoryId: number): Game {
  const remainingCategories = game.categories.filter((category) => category.id !== categoryId);

  return {
    ...game,
    categories: remainingCategories,
  };
}

export function updateQuestion({
  game,
  categoryId,
  questionId,
  question = "",
  answer = "",
  questionImageUrl = "",
  answerImageUrl = "",
}: {
  game: Game;
  categoryId: number;
  questionId: number;
  question?: string;
  answer?: string;
  questionImageUrl?: string;
  answerImageUrl?: string;
}): Game {
  const change = {
    ...game,
    categories: game.categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          questions: category.questions.map((q) => {
            if (q.id === questionId) {
              return {
                ...q,
                question,
                answer,
                imageUrl: questionImageUrl,
                answerImageUrl,
              };
            }
            return q;
          }),
        };
      }
      return category;
    }),
  };

  return change;
}

export const gameConverter = {
  toFirestore(game: Game): DocumentData {
    return {
      name: game.name,
      creatorId: game.creatorId,
      categories: game.categories,
      type: game.type,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Game {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      creatorId: data.creatorId,
      name: data.name,
      categories: data.categories,
      type: data.type,
    };
  },
};
