import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./services/firebase.service";

import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalStyle } from "./components/GlobalStyle";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // 5 min (default)
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <GlobalStyle />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
