import React from "react";
import { Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { theme, fontSize } from "../views/theme";
import { CreateGameView } from "./views/CreateGameViewJeopardy";
import { Destination, ErrorView } from "../views/ErrorView";
import { GameView } from "./views/GameViewJeopardy";
import { PlayView } from "./views/PlayViewJeopardy";

export class Jeopardy extends React.Component {
  render() {
    return (
      <Theme>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/jeopardy/create-game" component={CreateGameView} />
            <Route exact path="/jeopardy/room/:roomId" component={PlayView} />
            <Route path="/jeopardy/game/:gameId" component={GameView} />
            <Route>
              <ErrorView error={new Error("Requested page could not be found")} destination={Destination.JEOPARDY} />
            </Route>
          </Switch>
        </ThemeProvider>
      </Theme>
    );
  }
}

const Theme = styled.div`
  height: 100%;
  font-family: "heebo", sans-serif;
  color: ${theme.text.primary};
  background-color: ${theme.bg.secondary};
  font-size: ${fontSize};
`;
