import styled from "styled-components";

export interface TextProps {
  fontSize?: number;
  color?: string;
}

export interface TitleProps {
  fontSize?: number;
  color?: string;
}

export const Title = styled.h1<TitleProps>`
  font-size: 2rem;
  text-align: center;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  color: ${(props) => props.color || "#272727"};
`;

export const HeroText = styled.h1`
  font-size: 5rem;
  text-align: center;
  color: #272727;
`;

export const Description = styled.h1`
  font-size: 1.3rem;
  text-align: center;
  margin: 1rem 0;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  color: #272727;
`;

export const Text = styled.div<TextProps>`
  font-size: ${(props) => props.fontSize + "rem" || "1.3rem"};
  text-align: center;
  margin: 1rem 0;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  color: ${(props) => props.color || "#272727"};
`;

export const GameName = styled(Text)`
  margin: 0;
`;

export const PlayerText = styled.div<TextProps>`
  font-size: ${(props) => props.fontSize + "rem" || "1.3rem"};
  text-align: center;
  margin: 0.1rem 0;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  color: #272727;
`;

export const TextLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80vw;
  max-width: 30rem;
  font-size: 1.3rem;
  text-align: center;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  padding: 0.1rem;
  color: #272727;
`;
