import React from "react";

import { Container } from "../../components/Container";
import { Text, PlayerText } from "../../components/Typography";
import { StyledButton } from "../../components/Buttons";
import { StyledInput } from "../../components/Form";
import { StyledRow } from "../../components/StyledRows";
import { NakedButton } from "../../components/Buttons";
import { Footer } from "../../components/Footer";

import { useHistory } from "react-router-dom";
import { Room, startGame, useUpdateRoom } from "../model/Room";
import { useRoomUser } from "../../hooks/useRoomUser";
import { Game } from "../model/Game";
import QRCodeCanvas from "qrcode.react";
import { getSpectatorCollectionRef, getSpectatorDocRef } from "../../services/spectator.service";
import { getDocs, limit, query, updateDoc, where } from "firebase/firestore";
import { useFirestoreDocumentUpdateMutation } from "../../hooks/useFirestoreUpdateDocumentMutation";
import { getSiteUrl } from "../../services/site.service";

interface Props {
  game: Game;
  room: Room;
}

export const LobbyView: React.FC<Props> = ({ game, room }) => {
  //console.log("LobbyView");
  const history = useHistory();
  const user = useRoomUser(room);
  const [spectatorCode, setSpectatorCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutate } = useUpdateRoom(room.id);
  const formRef = React.useRef<any>();

  const { mutate: updateSpectator } = useFirestoreDocumentUpdateMutation(getSpectatorDocRef(user.uid));

  async function addSpectatorToRoom(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    const res = await getDocs(query(getSpectatorCollectionRef(), where("code", "==", spectatorCode), limit(1)));
    //console.log(res);

    if (res.empty) {
      formRef.current?.setCustomValidity("Spectator code is invalid");
    } else {
      const spectator = res.docs[0];
      await updateDoc(getSpectatorDocRef(spectator.id), {
        roomId: room.id,
      });
      setSpectatorCode("");
    }
    setIsLoading(false);
  }

  async function spectatorLeaveRoom() {
    await updateSpectator({
      roomId: "",
    });
    history.replace("/spectate");
  }

  return (
    <Container>
      <NakedButton onClick={() => (user.isSpectator ? spectatorLeaveRoom() : history.replace("/"))}>Back</NakedButton>
      <Text fontSize={2}>{game.name.toUpperCase()} • Lobby</Text>
      <Text fontSize={2}>Join game: {room.code}</Text>
      {user.isSpectator ? (
        <QRCodeCanvas bgColor="transparent" includeMargin={true} value={getSiteUrl(`/?code=${room.code}`)} size={400} />
      ) : null}
      {user.player ? (
        <>
          <PlayerText>Waiting for the host to start the game</PlayerText>
          <Text>Welcome {user.player.name}!</Text>
        </>
      ) : null}

      {Object.values(room.players).map((player) => (
        <StyledRow key={player.id}>
          <PlayerText>{player.name}</PlayerText>
          <PlayerText>{player.score}</PlayerText>
        </StyledRow>
      ))}

      {user.isAdmin ? (
        <Footer>
          <PlayerText fontSize={2}>Waiting for players to join</PlayerText>
          <PlayerText fontSize={2}>Start the game once ready</PlayerText>
          <StyledButton variant="green" onClick={() => mutate(startGame())}>
            <Text color="white">{"START GAME"}</Text>
          </StyledButton>

          <form onSubmit={addSpectatorToRoom}>
            <StyledInput
              ref={formRef}
              id="code"
              minLength={4}
              maxLength={4}
              required
              placeholder="Spectator code"
              type={"tel"}
              value={spectatorCode}
              onChange={(e) => setSpectatorCode(e.target.value)}
            />
            <StyledButton type="submit">
              {isLoading ? <Text color="white">Loading...</Text> : <Text color="white">Add Spectator</Text>}
            </StyledButton>
          </form>
        </Footer>
      ) : null}
    </Container>
  );
};
