import React, { useState } from "react";
import { useAuthSignInAnonymously, useAuthSignInWithRedirect } from "@react-query-firebase/auth";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "../../components/Container";
import { StyledButton } from "../../components/Buttons";
import { Title, Text } from "../../components/Typography";

enum SingInMethod {
  anonymous = "/",
  spectator = "/spectate",
}

export const LoginView: React.FC = () => {
  const { search } = useLocation();
  const auth = getAuth();
  const history = useHistory();
  const provider = new GoogleAuthProvider();
  const signInWithRedirect = useAuthSignInWithRedirect(auth);
  const [signInMethod, setSignInMethod] = useState<SingInMethod>();

  const signInAnonymously = useAuthSignInAnonymously(auth);

  const handleSignInAnonymously = async (signInMethod: SingInMethod) => {
    setSignInMethod(signInMethod);
    await signInAnonymously.mutateAsync({ email: "", password: "" });

    history.replace(`${signInMethod}${search}`);
  };

  const signInWithRedirectFunction = () => {
    setSignInMethod(undefined);
    signInWithRedirect.mutate({ provider });
  };

  const isLoading = signInWithRedirect.isLoading || signInAnonymously.isLoading;
  const error = signInWithRedirect.error || signInAnonymously.error;

  return (
    <Container variant="center">
      <Title>QUIZIFY</Title>
      <Text fontSize={2}>THE PLACE TO PLAY DEM QUIZ GAMES</Text>
      <StyledButton variant="brown" onClick={signInWithRedirectFunction} disabled={isLoading}>
        <Text color="white">{signInWithRedirect.isLoading ? "Signing in..." : "SIGN IN WITH GOOGLE"}</Text>
      </StyledButton>
      <StyledButton
        variant="green"
        onClick={() => handleSignInAnonymously(SingInMethod.spectator)}
        disabled={isLoading}
      >
        <Text color="white">{isLoading && signInMethod === SingInMethod.spectator ? "Entering..." : "ENTER AS SPECTATOR"}</Text>
      </StyledButton>
      <StyledButton onClick={() => handleSignInAnonymously(SingInMethod.anonymous)} disabled={isLoading}>
        <Text color="white">{isLoading && signInMethod === SingInMethod.anonymous ? "Entering..." : "ENTER ANONYMOUSLY"}</Text>
      </StyledButton>
      {error && <p style={{ color: "red" }}>{error.message}</p>}
    </Container>
  );
};
