import React from "react";

import { Container } from "../../components/Container";
import { MainHeader } from "../components/MainHeader";
import { GameGrid } from "../components/GameGrid";
import { Text, GameName } from "../../components/Typography";
import { CategoryItem } from "../components/CategoryItem";

import { Category, Game, Question } from "../model/Game";
import { getPlayersByScore, pauseGame, Player, Room, startQuestion, useUpdateRoom } from "../model/Room";
import { useHistory } from "react-router-dom";
import { useRoomUser } from "../../hooks/useRoomUser";
import { areStringFieldsEmpty } from "../../util";
import { StyledHeaderButton } from "../../components/Buttons";
import { HeaderRowOne, HeaderRowTwo } from "../../components/StyledRows";
import { GameCell } from "../components/GameCell";
import { TeamsWrap, TeamWrap } from "../components/TeamWrap";

interface Props {
  game: Game;
  room: Room;
}

export const QuestionsView: React.FC<Props> = ({ game, room }) => {
  //console.log("QuestionsView");
  const history = useHistory();
  const user = useRoomUser(room);
  const { mutate } = useUpdateRoom(room.id);
  const playerCount = Object.keys(room.players).length;

  function renderQuestion(category: Category, question: Question) {
    const roomQuestion = room.questions[`${category.id}-${question.id}`];
    const isValidQuestion = !areStringFieldsEmpty(question);
    const canAnswer = isValidQuestion && !roomQuestion?.isAnswered;

    return (
      <CategoryItem
        questionFilled={!canAnswer}
        key={question.id}
        onClick={user.isAdmin && canAnswer ? () => mutate(startQuestion(category, question)) : undefined}
      >
        <Text color="white">{question.value}</Text>
      </CategoryItem>
    );
  }

  function renderPlayer(player: Player) {
    return (
      <TeamWrap title={player.name} compact={playerCount > 5} key={player.id}>
        <Text color="white">
          {player.name} {player.score}
        </Text>
      </TeamWrap>
    );
  }

  return (
    <Container>
      {user.isSpectator ? null : (
        <MainHeader directionVariant="column">
          <HeaderRowOne justifyContentVariant="flex-start">
            <StyledHeaderButton onClick={() => (user.isAdmin ? mutate(pauseGame()) : history.replace("/"))}>
              <Text color="white">{"Back"}</Text>
            </StyledHeaderButton>
          </HeaderRowOne>
          <HeaderRowTwo>
            <GameName fontSize={1.5} color="white">
              {game.name} • {room.code}
            </GameName>
          </HeaderRowTwo>
        </MainHeader>
      )}
      <GameGrid withPlayers={playerCount > 0} isSpectator={user.isSpectator} columnCount={game.categories.length}>
        {game.categories.map((category, _categoryIndex) => (
          <GameCell backPaint="black" key={category.id}>
            <CategoryItem titleName>
              <Text color="white">{category.name.toUpperCase()}</Text>
            </CategoryItem>
            {category.questions.map((question) => renderQuestion(category, question))}
          </GameCell>
        ))}
      </GameGrid>
      <TeamsWrap>{getPlayersByScore(room).map(renderPlayer)}</TeamsWrap>
    </Container>
  );
};
