import styled from "styled-components";

export type directionVariant = "row" | "column";
export type alignItemsVariant = "center" | "flex-start" | "flex-end";
export type justifyContentVariant = "center" | "flex-start" | "flex-end" | "space-around" | "space-between" | "space-evenly";

export interface MainHeaderProps {
  directionVariant?: directionVariant;
  alignItemsVariant?: alignItemsVariant;
  justifyContentVariant?: justifyContentVariant;
}

export const MainHeader = styled.div<MainHeaderProps>`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: ${(props) => props.directionVariant || "row"};
  align-items: ${(props) => props.alignItemsVariant || "center"};
  justify-content: ${(props) => props.justifyContentVariant || "center"};
  width: 100vw;
  height: 10vh;
  background-color: #6da3a7;
`;