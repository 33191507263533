import styled from "styled-components";

export const StyledInput = styled.input`
  width: 80vw;
  max-width: 30rem;
  height: 3.2rem;
  border-radius: 4px;
  border: 1px solid black;
  padding: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
  font-family: "Amatic SC", cursive;
  font-weight: 700;

  &:focus {
    outline: none;
  }
`;
