import React from "react";
import { Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { Destination, ErrorView } from "../views/ErrorView";
import { theme, fontSize } from "../views/theme";
import { CreateGameView } from "./views/CreateGameViewQuiz";
import { GameView } from "./views/GameViewQuiz";
import { PlayView } from "./views/PlayViewQuiz";

export class QuizApp extends React.Component {
  render() {
    return (
      <Theme>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/quiz/create-game" component={CreateGameView} />
            <Route exact path="/quiz/room/:roomId" component={PlayView} />
            <Route path="/quiz/game/:gameId" component={GameView} />
            <Route>
              <ErrorView error={new Error("Requested page could not be found")} destination={Destination.QUIZ} />
            </Route>
          </Switch>
        </ThemeProvider>
      </Theme>
    );
  }
}

const Theme = styled.div`
  height: 100%;
  font-family: "heebo", sans-serif;
  color: ${theme.text.primary};
  background-color: ${theme.bg.secondary};
  font-size: ${fontSize};
`;
