import { DocumentData, DocumentReference, updateDoc, UpdateData, FirestoreError } from "firebase/firestore";
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";

export function useFirestoreDocumentUpdateMutation<T = DocumentData>(
  ref: DocumentReference<T>,
  useMutationOptions?: UseMutationOptions<void, FirestoreError, UpdateData<T>>,
): UseMutationResult<void, FirestoreError, UpdateData<T>> {
  return useMutation<void, FirestoreError, UpdateData<T>>((data) => {
    return updateDoc<T>(ref, data);
  }, useMutationOptions);
}
