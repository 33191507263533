import React, { useRef, useState } from "react";
import { Redirect } from "react-router";

import { Game, gameConverter, Question, updateQuestion } from "../model/Game";
import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import { uploadImageToFirebase } from "../../services/uploadImageToFirebase";
import { useUser } from "../../hooks/useUser";

import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { LoadingView } from "../../views/LoadingView";
import { Container } from "../../components/Container";
import { Text, GameName } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { StyledButton } from "../../components/Buttons";

interface RouteParams {
  categoryId: string;
  questionId: string;
}

export const EditQuestionView: React.FC<{ game: Game }> = ({ game }) => {
  //console.log("EditQuestionView");
  const [questionUrl, setQuestionUrl] = useState<string>("");
  const [answerUrl, setAnswerUrl] = useState<string>("");
  const user = useUser();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const ref = doc(getFirestore(), "templates", game.id).withConverter(gameConverter);
  const { mutate, isLoading, isSuccess } = useFirestoreDocumentMutation(ref);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const hiddenQuestionFileInput = React.useRef<HTMLInputElement>(null);
  const hiddenAnswerFileInput = React.useRef<HTMLInputElement>(null);
  const auth = getAuth();
  const { data: userData, isLoading: userIsLoading } = useAuthUser(["user"], auth);

  const questionId = Number(params.questionId);

  const question =
    game.questions[questionId] ??
    ({
      answer: "",
      question: "",
      value: 0,
      questionImageUrl: "",
      answerImageUrl: "",
    } as Question);

  const isNew = !game.questions[questionId];

  const [questionText, setQuestionText] = useState(question?.question);
  const [answerText, setAnswerText] = useState(question?.answer);
  const [value, setValue] = useState(question?.value);

  const questionImageRef = useRef("");
  const answerImageRef = useRef("");

  if (userIsLoading || isLoading) {
    return <LoadingView />;
  }

  if (!userData) {
    return <Redirect to="/" />;
  }

  const handleUpdateQuestion = async () => {
    await mutate(
      updateQuestion({
        game,
        questionId,
        question: questionText,
        answer: answerText,
        questionImageUrl: questionImageRef.current || question.questionImageUrl,
        answerImageUrl: answerImageRef.current || question.answerImageUrl,
        value,
      }),
    );
  };

  const handleQuestionFileChanged = async (file: FileList | null) => {
    setIsViewLoading(true);
    if (file && file.length > 0) {
      const savedImageUrl = await uploadImageToFirebase(file[0], user);
      if (savedImageUrl) {
        questionImageRef.current = savedImageUrl;
        setQuestionUrl(savedImageUrl);
      }
    }
    setIsViewLoading(false);
  };

  const handleAnswerFileChanged = async (file: FileList | null) => {
    setIsViewLoading(true);
    if (file && file.length > 0) {
      const savedImageUrl = await uploadImageToFirebase(file[0], user);
      if (savedImageUrl) {
        answerImageRef.current = savedImageUrl;
        setAnswerUrl(savedImageUrl);
      }
    }
    setIsViewLoading(false);
  };

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const currentQuestionImageUrl = questionUrl || question.questionImageUrl;
  const currentAnswerImageUrl = answerUrl || question.answerImageUrl;

  if (isSuccess) {
    return <Redirect to={`/quiz/game/${game.id}`} />;
  }

  return (
    <Container>
      <Text fontSize={2}>Edit {question.question || "Question"}</Text>
      <GameName fontSize={2}>Question</GameName>
      <StyledInput
        value={questionText}
        required
        onChange={(e) => setQuestionText(e.target.value)}
        minLength={1}
        autoFocus
      ></StyledInput>
      <>
        <StyledButton variant="brown" onClick={() => handleClick(hiddenQuestionFileInput)}>
          <Text color="white">{"UPLOAD IMAGE FOR QUESTION"}</Text>
        </StyledButton>
        <input
          type="file"
          ref={hiddenQuestionFileInput}
          onChange={(e) => handleQuestionFileChanged(e.target.files)}
          style={{ display: "none" }}
        />
      </>
      {currentQuestionImageUrl && (
        <img className="question-image" src={`${currentQuestionImageUrl}`} alt={"question"} width={250} height={250} />
      )}
      <GameName fontSize={2}>ANSWER</GameName>
      <StyledInput
        value={answerText}
        required
        onChange={(e) => setAnswerText(e.target.value)}
        minLength={1}
      ></StyledInput>
      <>
        <StyledButton variant="brown" onClick={() => handleClick(hiddenAnswerFileInput)}>
          <Text color="white">{"UPLOAD IMAGE FOR ANSWER"}</Text>
        </StyledButton>
        <input
          type="file"
          ref={hiddenAnswerFileInput}
          onChange={(e) => handleAnswerFileChanged(e.target.files)}
          style={{ display: "none" }}
        />
      </>

      {currentAnswerImageUrl && (
        <img className="question-image" src={`${currentAnswerImageUrl}`} alt={"question"} width={250} height={250} />
      )}
      <>
        <GameName fontSize={2}>Value</GameName>
        <StyledInput
          type={"tel"}
          value={value}
          required
          onChange={(e) =>
            setValue(!Number.isNaN(Number.parseInt(e.target.value)) ? Number.parseInt(e.target.value) : 0)
          }
          minLength={1}
        ></StyledInput>
      </>

      <StyledButton
        onClick={() => (!isViewLoading ? handleUpdateQuestion() : undefined)}
        variant={"green"}
        type="button"
        disabled={isLoading || isViewLoading}
      >
        {isNew ? (
          <Text color="white">{isLoading || isViewLoading ? "..." : "CREATE QUESTION"}</Text>
        ) : (
          <Text color="white">{"UPDATE QUESTION"}</Text>
        )}
      </StyledButton>
      <StyledButton
        onClick={() => {
          history.replace(`/quiz/game/${game.id}`);
        }}
      >
        <Text color="white">{"BACK"}</Text>
      </StyledButton>
    </Container>
  );
};
