import { useUser } from "./useUser";
import { Player, Room as JeopardyRoom } from "../jeopardy/model/Room";
import { User } from "firebase/auth";
import { Room as QuizRoom } from "../quiz/model/Room";

export enum RoomUserRole {
  ADMIN = "ADMIN",
  PLAYER = "PLAYER",
  SPECTATOR = "SPECTATOR",
}

export interface RoomUser {
  uid: string;
  user: User;
  role: RoomUserRole;
  player: Player;
  isAdmin: boolean;
  isPlayer: boolean;
  isSpectator: boolean;
}

export function useRoomUser(room: JeopardyRoom | QuizRoom | null): RoomUser {
  const user = useUser();

  const role =
    room && room.creatorId === user.uid
      ? RoomUserRole.ADMIN
      : room && room.players[user.uid]
      ? RoomUserRole.PLAYER
      : RoomUserRole.SPECTATOR;

  return {
    uid: user.uid,
    user,
    role,
    player: room && room.players[user.uid],
    isAdmin: role === RoomUserRole.ADMIN,
    isPlayer: role === RoomUserRole.PLAYER,
    isSpectator: role === RoomUserRole.SPECTATOR,
  };
}
