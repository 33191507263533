import styled from "styled-components";

export type directionVariant = "row" | "column";
export type alignItemsVariant = "center" | "flex-start" | "flex-end";
export type justifyContentVariant =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between"
  | "space-evenly";

export interface HeaderRowProps {
  directionVariant?: directionVariant;
  alignItemsVariant?: alignItemsVariant;
  justifyContentVariant?: justifyContentVariant;
}

export interface AnswerRowProps {
  answerCorrect?: boolean;
}

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  max-width: 400px;
`;

export const HeaderRowOne = styled.div<HeaderRowProps>`
  display: flex;
  flex-direction: ${(props) => props.directionVariant || "row"};
  align-items: ${(props) => props.alignItemsVariant || "center"};
  justify-content: ${(props) => props.justifyContentVariant || "center"};
  width: 100vw;
  height: 5vh;
`;

export const HeaderRowTwo = styled.div<HeaderRowProps>`
  display: flex;
  flex-direction: ${(props) => props.directionVariant || "row"};
  align-items: ${(props) => props.alignItemsVariant || "center"};
  justify-content: ${(props) => props.justifyContentVariant || "center"};
  width: 100vw;
  height: 5vh;
`;

export const HeaderButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
`;

export const SaveAnswerRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: 1em;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;
`;

export const AnswerListRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 5vh;
`;

export const NameRow = styled(AnswerListRow)`
  justify-content: center;
  align-items: center;
  width: 30vw;
  font-size: 0.9em;
`;

export const ScoreRow = styled(AnswerListRow)`
  justify-content: center;
  align-items: center;
  width: 10vw;
`;

export const AnswerRow = styled(AnswerListRow)<AnswerRowProps>`
  justify-content: center;
  align-items: center;
  width: 60vw;
  font-size: 0.9em;
`;
