import React, { useState } from "react";
import { Redirect } from "react-router";

import { doc, getFirestore } from "firebase/firestore";
import { addCategory, Game, gameConverter } from "../model/Game";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { useHistory } from "react-router-dom";

import { LoadingView } from "../../views/LoadingView";
import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { Container } from "../../components/Container";
import { Title, Text } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { StyledButton } from "../../components/Buttons";

export const CreateCategoryView: React.FC<{ game: Game }> = ({ game }) => {
  //console.log("CreateCategoryView");
  const history = useHistory();
  const ref = doc(getFirestore(), "templates", game.id).withConverter(gameConverter);
  const { mutate, isLoading, isSuccess } = useFirestoreDocumentMutation(ref);
  const auth = getAuth();
  const { data: userData, isLoading: userIsLoading } = useAuthUser(["user"], auth);
  const [categoryName, setCategoryName] = useState("");

  if (userIsLoading || isLoading) {
    return <LoadingView />;
  }

  if (!userData) {
    return <Redirect to="/" />;
  }

  if (isSuccess) {
    return <Redirect to={`/jeopardy/game/${game.id}`} />;
  }

  return (
    <form onSubmit={() => mutate(addCategory(game, categoryName))}>
      <Container>
        <Title>Enter Category name</Title>
        <StyledInput
          value={categoryName}
          required
          onChange={(e) => setCategoryName(e.target.value)}
          minLength={1}
          autoFocus
        ></StyledInput>
        <StyledButton variant="brown" type="submit" disabled={isLoading}>
          <Text color="white">{"CREATE CATEGORY"}</Text>
        </StyledButton>
        <StyledButton
          onClick={() => {
            history.replace(`/jeopardy/game/${game.id}`);
          }}
        >
          <Text color="white">{"BACK"}</Text>
        </StyledButton>
      </Container>
    </form>
  );
};
