import React from "react";

import { collection, getFirestore, query, where } from "firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import { QueryDocumentSnapshot } from "@firebase/firestore";
import { Container } from "../../components/Container";
import { Text, Title } from "../../components/Typography";
import { StyledButton } from "../../components/Buttons";
import { GameType } from "../../constants";
import { useUser } from "../../hooks/useUser";

interface RouteProps {
  type: GameType;
}

export const TemplatesListView: React.FC = () => {
  const { type } = useParams<RouteProps>();
  const history = useHistory();
  const user = useUser();
  const ref = query(
    collection(getFirestore(), "templates"),
    where("creatorId", "==", user.uid),
    where("type", "==", type),
  );
  const { isLoading, data } = useFirestoreQuery(["templates"], ref, { subscribe: true });
  const typeName = type === GameType.QUIZ ? "quiz" : "jeopardy";

  function renderGame(snapshot: QueryDocumentSnapshot) {
    const template = snapshot.data();

    return (
      <StyledButton
        variant="brown"
        type="button"
        key={snapshot.id}
        onClick={() => history.push(`/${typeName}/game/${snapshot.id}`)}
        disabled={isLoading}
      >
        <Text color="white">{template.name}</Text>
      </StyledButton>
    );
  }

  return (
    <Container variant="center">
      <Title>{type}</Title>
      <StyledButton variant="green" type="button" onClick={() => history.push(`/${typeName}/create-game`)}>
        <Text color="white">{"CREATE GAME"}</Text>
      </StyledButton>
      {isLoading ? <Text>Loading created games...</Text> : <>{data?.docs.map(renderGame)}</>}
      <StyledButton type="button" onClick={() => history.replace("/")}>
        <Text color="white">{"BACK"}</Text>
      </StyledButton>
    </Container>
  );
};
