import styled from "styled-components";

type ContainerVariant = "center" | "default";

interface ContainerProps {
  variant?: ContainerVariant;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.variant === "center" ? "center" : "flex-start")};
  min-height: 100vh;
  text-align: center;
  box-sizing: border-box;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  background-image: linear-gradient(#ebeabb, #6da3a7);
`;

export const Content = styled.div`
  margin: auto;
`;
