import styled from "styled-components";
import { ChangeEvent, useState } from "react";
import { Room, GameSettings, useUpdateRoom } from "../jeopardy/model/Room";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 0;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: #436a6d;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

interface StyledToggleProps {
  room: Room;
  settingName: string;
}

export const StyledToggle: React.FC<StyledToggleProps> = ({ room, settingName }) => {
  const [checked, setChecked] = useState(room.settings[settingName as keyof GameSettings]);
  const { mutate } = useUpdateRoom(room.id);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);

    const settings = room && room.settings;
    Object.keys(settings).forEach((setting) => {
      if (setting === settingName) {
        settings[setting as keyof GameSettings] = !settings[setting as keyof GameSettings];
      }
    });

    mutate({ settings });
  };

  return (
    <Label>
      <Input checked={checked} type="checkbox" onChange={handleChange} />
      <Switch />
    </Label>
  );
};
