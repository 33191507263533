import React from "react";

import { useFirestoreDocumentData, useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { addSpectator, getSpectatorDocRef } from "../../services/spectator.service";
import { useLogout, useUser } from "../../hooks/useUser";
import { Redirect } from "react-router-dom";
import { Container, Content } from "../../components/Container";
import { HeroText, Text, Title } from "../../components/Typography";
import { NakedButton } from "../../components/Buttons";

const SpectatorView: React.FC = () => {
  const user = useUser();
  const { logout } = useLogout();
  const { mutate, isLoading: isCreatingCode } = useFirestoreDocumentMutation(getSpectatorDocRef(user.uid));

  const { data } = useFirestoreDocumentData(
    ["spectator", user.uid],
    getSpectatorDocRef(user.uid),
    {
      subscribe: true,
    },
    {
      onSuccess: (data) => {
        if (!data && !isCreatingCode) {
          mutate(addSpectator(user.uid));
        }
      },
    },
  );

  if (data?.roomId) {
    return <Redirect to={`/jeopardy/room/${data.roomId}`} />;
  }

  return (
    <Container variant="center">
      <NakedButton onClick={() => logout()}>Back</NakedButton>
      <Content>
        <Title>Quizify spectator code</Title>
        {data ? <HeroText>{data.code}</HeroText> : <Text>Loading...</Text>}
      </Content>
    </Container>
  );
};

export default SpectatorView;
