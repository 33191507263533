import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { GameType } from "../../constants";

export interface Question {
  id: number;
  question: string;
  answer: string;
  value: number;
  questionImageUrl: string;
  answerImageUrl: string;
}

export interface Game {
  id: string;
  creatorId: string;
  name: string;
  questions: Question[];
  type: GameType;
}

export function createGame(name: string, creatorId: string, type: GameType): Game {
  return {
    id: "temp",
    creatorId,
    name,
    questions: [],
    type,
  };
}

export function createQuestion({
  game,
  question = "",
  answer = "",
  value,
  questionImageUrl = "",
  answerImageUrl = "",
}: {
  game: Game;
  question?: string;
  answer?: string;
  value: number;
  questionImageUrl?: string;
  answerImageUrl?: string;
}): Game {
  const change = {
    ...game,
    questions: [
      ...game.questions,
      {
        id: game.questions.length,
        question,
        answer,
        value,
        questionImageUrl,
        answerImageUrl,
      },
    ],
  };

  return change;
}

export function updateQuestion({
  game,
  questionId,
  question = "",
  answer = "",
  value,
  questionImageUrl = "",
  answerImageUrl = "",
}: {
  game: Game;
  questionId: number;
  question?: string;
  answer?: string;
  value: number;
  questionImageUrl?: string;
  answerImageUrl?: string;
}): Game {
  const change = {
    ...game,
    questions: [
      ...game.questions.map((q) => {
        // handle question update
        if (q.id === questionId) {
          return {
            ...q,
            question,
            answer,
            value,
            questionImageUrl,
            answerImageUrl,
          };
        }

        return q;
      }),
    ],
  };

  // handle new question
  if (!game.questions.find((q) => q.id === questionId)) {
    change.questions.push({
      id: questionId,
      question,
      answer,
      value,
      questionImageUrl,
      answerImageUrl,
    });
  }

  return change;
}

export const gameConverter = {
  toFirestore(game: Game): DocumentData {
    return {
      name: game.name,
      creatorId: game.creatorId,
      questions: game.questions,
      type: game.type,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Game {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      creatorId: data.creatorId,
      name: data.name,
      questions: data.questions,
      type: data.type,
    };
  },
};
