import styled from "styled-components";

export type directionVariant = "row" | "column";

export interface CategoryItemProps {
  titleName?: boolean;
  questionFilled?: boolean;
  categoryCount?: number;
  directionVariant?: directionVariant;
}

export const CategoryItem = styled.div<CategoryItemProps>`
  display: flex;
  flex: ${(props) => (props.titleName ? 0.5 : 1)};
  flex-direction: ${(props) => props.directionVariant || "row"};
  text-align: center;
  align-items: center;
  opacity: ${(props) => (props.questionFilled ? 0.6 : 1)};
  max-height: ${(props) => (props.titleName ? "8vh" : "")};
  max-width: ${(props) => (props.titleName ? props.categoryCount + "vw" : "")};
  justify-content: center;
  border: 2px solid black;
  border-radius: ${(props) => (props.titleName ? 0 : "6px")};
  background-color: ${(props) => (props.titleName ? "#0359fc" : "#0393fc")};
`;
