// Saves a new message containing an image in Firebase.

import { User } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// This first saves the image in Firebase storage.
export async function uploadImageToFirebase(file: File, user: User) {
  try {
    // Upload the image to Cloud Storage.
    const filePath = `${user.uid}/${file.name}`;
    const newImageRef = ref(getStorage(), filePath);
    await uploadBytesResumable(newImageRef, file);

    // Generate a public URL for the file.
    const publicImageUrl = await getDownloadURL(newImageRef);

    return publicImageUrl;
  } catch (error) {
    console.error("There was an error uploading a file to Cloud Storage:", error);
  }
}
