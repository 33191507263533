import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { generateRoomCode } from "./room.service";

export interface Spectator {
  code: string;
  userId: string;
  roomId?: string;
}

export function addSpectator(userId: string): Spectator {
  return {
    code: generateRoomCode(),
    userId,
  };
}

export function getSpectatorCollectionRef(): CollectionReference<Spectator> {
  return collection(getFirestore(), "spectators").withConverter(spectatorConverter);
}

export function getSpectatorDocRef(spectatorId: string): DocumentReference<Spectator> {
  return doc(getFirestore(), "spectators", spectatorId).withConverter(spectatorConverter);
}

export const spectatorConverter = {
  toFirestore(spectator: Spectator): DocumentData {
    return spectator;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Spectator {
    const data = snapshot.data(options);

    return {
      code: data.code,
      userId: data.userId,
      roomId: data.roomId,
    };
  },
};
