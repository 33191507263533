import React from "react";

import { Game, Question } from "../model/Game";
import { getPlayersByScore, pauseGame, Player, Room, useUpdateRoom, startQuestion } from "../model/Room";
import { useHistory } from "react-router-dom";
import { useRoomUser } from "../../hooks/useRoomUser";
import { TeamsWrap, TeamWrap, QuestionNumberWrap } from "../../jeopardy/components/TeamWrap";
import { GameName, Text } from "../../components/Typography";
import { Container } from "../../components/Container";
import { StyledHeaderButton } from "../../components/Buttons";
import { HeaderRowOne, HeaderRowTwo } from "../../components/StyledRows";
import { MainHeader } from "../../jeopardy/components/MainHeader";

interface Props {
  game: Game;
  room: Room;
}

export const MidRoundView: React.FC<Props> = ({ game, room }) => {
  //console.log("MidRoundView");
  const history = useHistory();
  const user = useRoomUser(room);
  const { mutate } = useUpdateRoom(room.id);

  const roomQuestionId =
    room.questions[`0-${Object.values(room.questions).length - 1}`] &&
    room.questions[`0-${Object.values(room.questions).length - 1}`].questionId;
  const question = game.questions[roomQuestionId === undefined ? 0 : roomQuestionId + 1];
  //const unanswerdQuestions = Object.values(game.questions).filter((q) => q.id !== room.questions).length;
  //const unAnsweredQuestion = Object.values(room.questions).find((question) => !question.isAnswered);

  function renderPlayer(player: Player) {
    return (
      <TeamWrap key={player.id}>
        <Text color="white">
          {player.name} {player.score}
        </Text>
      </TeamWrap>
    );
  }

  function renderQuestions(question: Question, index: number) {
    const roomQuestion = room.questions[`0-${question.id}`];
    return (
      <QuestionNumberWrap isAnswered={roomQuestion && roomQuestion.isAnswered} key={index}>
        <Text color="white">{index + 1}</Text>
      </QuestionNumberWrap>
    );
  }

  function startQuiz() {
    mutate(startQuestion(question));
  }

  return (
    <Container>
      {user.isSpectator ? null : (
        <MainHeader directionVariant="column">
          <HeaderRowOne justifyContentVariant="space-between">
            <StyledHeaderButton onClick={() => (user.isAdmin ? mutate(pauseGame()) : history.replace("/"))}>
              <Text color="white">{"Back"}</Text>
            </StyledHeaderButton>
            {user.isAdmin ? (
              <StyledHeaderButton onClick={() => startQuiz()}>
                <Text color="white">
                  {room.questions[`0-0`] && room.questions[`0-0`].isAnswered ? "Next Question" : "Start quiz"}
                </Text>
              </StyledHeaderButton>
            ) : null}
          </HeaderRowOne>
          <HeaderRowTwo>
            <GameName fontSize={1.5} color="white">
              {game.name} • {room.code}
            </GameName>
          </HeaderRowTwo>
        </MainHeader>
      )}

      <TeamsWrap>{game.questions.map((q, i) => renderQuestions(q, i))}</TeamsWrap>
      <TeamsWrap directionVariant="column">{getPlayersByScore(room).map(renderPlayer)}</TeamsWrap>
    </Container>
  );
};
