import { lighten } from "polished";
import { MouseEventHandler } from "react";
import { css, keyframes } from "styled-components";

// common visual configuration used by various components
export const theme = {
  text: {
    primary: "#222",
    secondary: "#555",
    tertiary: "#FFF",
    quaternary: "#FFB739",
    error: "#F00",
    shadow: "rgba(0, 0, 0, 0.5)",
    font: "inherit",
  },
  bg: {
    primary: "#6DA3A7",
    secondary: "#EBEABB",
    tertiary: "#2320C4",
    button: "#FFB739",
    disabled: "red",
    highlight: "#336699",
    font: "inherit",
  },
  size: {
    gridGap: "4px",
    header: "2.5em",
    footer: "2.5em",
    icon: "1.5em",
    lightColorLightness: 0.85,
    darkColorLightness: 0.5,
    font: "inherit",
  },
  fluid: {
    minFontSize: "20px",
    maxFontSize: "34px",
    minViewportSize: "320px",
    maxViewportSize: "1920px",
  },
  animation: {
    fadeIn: keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `,
    slideIn: (distance: string) => keyframes`
      0% {
        transform: translateY(${distance});
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    `,
    slideOut: (distance: string) => keyframes`
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(${distance});
        opacity: 0;
      }
    `,
    pulse: (color: string) => keyframes`
      0% {
        background: ${color};
      }
      50% {
        background: ${lighten(0.25, color)};
      }
      100% {
        background: ${color};
      }
    `,
  },
};

// resolve theme type
export type Theme = typeof theme;

// clickable element
export interface Clickable<T = Element> {
  onClick?: MouseEventHandler<T>;
}

// base font size calculation based on viewport size
export const fontSize = `calc(${theme.fluid.minFontSize} + (${parseInt(theme.fluid.maxFontSize, 10)} - ${parseInt(
  theme.fluid.minFontSize,
  10,
)}) * ((100vmin - ${theme.fluid.minViewportSize}) / (${parseInt(theme.fluid.maxViewportSize, 10)} - ${parseInt(
  theme.fluid.minViewportSize,
  10,
)})))`;

export function icon(filename: string, width = theme.size.icon, height = theme.size.icon, color = theme.text.primary) {
  return css`
    mask-image: url(${`/icons/${filename}`});
    mask-size: ${height} ${width};
    mask-repeat: no-repeat;
    mask-position: center center;
    background-color: ${color};
  `;
}
