import React, { useState } from "react";

import { Game } from "../model/Game";
import { Container } from "../../components/Container";
import { MainHeader } from "../components/MainHeader";
import {
  answerQuestion,
  AnswerResult,
  cancelQuestion,
  endQuestion,
  finishQuestionAnswering,
  rateAnswer,
  Room,
  RoomQuestion,
  useUpdateRoom,
} from "../model/Room";
import AnswerList from "../../components/AnswerList";
import { AnswerStatus, Question, QuestionStatus } from "../../types";
import { useRoomUser } from "../../hooks/useRoomUser";
import { HeaderRowOne, HeaderRowTwo, SaveAnswerRow } from "../../components/StyledRows";
import { StyledHeaderButton, StyledButton } from "../../components/Buttons";
import { Text, GameName } from "../../components/Typography";
import { GameGrid } from "../components/GameGrid";
import { GameCell } from "../components/GameCell";
import { CategoryItem } from "../components/CategoryItem";
import { StyledInput } from "../../components/Form";
import { CategoryWrap } from "../components/CategoryWrap";

interface Props {
  game: Game;
  room: Room;
  question: RoomQuestion;
}

export const QuestionView: React.FC<Props> = ({ game, room, question }) => {
  //console.log("QuestionView");
  const activeCategory = game.categories[question.categoryId];
  const activeQuestion = activeCategory.questions[question.questionId];
  const user = useRoomUser(room);
  const { mutate } = useUpdateRoom(room.id);

  const playerAnswer = user.player && question.answers?.[user.player.id];
  const isPlayerAnswered = playerAnswer?.isEditable === false;
  const [answerText, setAnswerText] = useState("");
  const activeQuestionAnswered =
    room.activeQuestionId === `${question.categoryId}-${question.questionId}` &&
    room.questions[room.activeQuestionId].isAnswered;

  function renderAdminAnswer() {
    return (
      <>
        <CategoryItem titleName>
          <Text color="white">Answer: {activeQuestion.answer}</Text>
        </CategoryItem>

        {activeQuestion.answerImageUrl &&
          {
            /*<Cell center>
            <img
              className="question-image"
              src={`${activeQuestion.answerImageUrl}`}
              alt={"answer"}
              width={400}
              height={400}
            />
        </Cell>*/
          }}
      </>
    );
  }

  function renderAdminEndButton() {
    if (activeQuestionAnswered) {
      return (
        <StyledHeaderButton onClick={() => mutate(endQuestion())}>
          <Text color="white" fontSize={1}>
            {"END QUESTION"}
          </Text>
        </StyledHeaderButton>
      );
    }
  }

  return (
    <Container>
      <MainHeader directionVariant="column">
        <HeaderRowOne justifyContentVariant="space-between">
          {user.isAdmin ? (
            <>
              <StyledHeaderButton onClick={() => mutate(finishQuestionAnswering(room))}>
                <Text color="white" fontSize={1}>
                  {"CLOSE ANSWERING"}
                </Text>
              </StyledHeaderButton>
              {renderAdminEndButton()}
              <StyledHeaderButton onClick={() => mutate(cancelQuestion())}>
                <Text color="white" fontSize={1}>
                  {"CANCEL ANSWERING"}
                </Text>
              </StyledHeaderButton>
            </>
          ) : null}
        </HeaderRowOne>
        <HeaderRowTwo>
          <GameName color="white">
            {activeCategory.name} • {activeQuestion.value}
          </GameName>
        </HeaderRowTwo>
      </MainHeader>

      <CategoryWrap>
        {activeQuestionAnswered ? null : (
          <GameCell question backPaint="black">
            <CategoryItem directionVariant="column">
              <Text color="white">{"QUESTION"}</Text>
              <Text color="white">{activeQuestion.question}</Text>
            </CategoryItem>
          </GameCell>
        )}
        {activeQuestion.imageUrl && (
          <GameCell>
            <img
              className="question-image"
              src={`${activeQuestion.imageUrl}`}
              alt={"question"}
              width={400}
              height={400}
            />
          </GameCell>
        )}
        {user.isAdmin ? renderAdminAnswer() : null}
        {!user.isAdmin && activeQuestionAnswered ? (
          <GameGrid questionClosed={activeQuestionAnswered}>
            <GameCell question backPaint="black">
              <CategoryItem directionVariant="column">
                <Text color="white">{"ANSWER"}</Text>
                <Text color="white">{activeQuestion.answer}</Text>
              </CategoryItem>
            </GameCell>
            {activeQuestion.answerImageUrl && (
              <GameCell>
                <img
                  className="question-answer-image"
                  src={`${activeQuestion.answerImageUrl}`}
                  alt={"answer"}
                  width={400}
                  height={400}
                />
              </GameCell>
            )}
          </GameGrid>
        ) : null}
        {user.player && !isPlayerAnswered ? (
          <SaveAnswerRow>
            <StyledInput
              value={answerText}
              required
              onChange={(e) => setAnswerText(e.target.value)}
              minLength={1}
              autoFocus
            ></StyledInput>
            <StyledButton
              variant="green"
              onClick={() => mutate(answerQuestion(room, user.player.id, answerText))}
              disabled={isPlayerAnswered}
            >
              <Text color="white">{"SAVE ANSWER"}</Text>
            </StyledButton>
          </SaveAnswerRow>
        ) : null}

        {!user.player || isPlayerAnswered ? (
          <AnswerList
            key={user.uid}
            question={formatQuestion()}
            onRateAnswer={user.isAdmin ? onRateAnswer : undefined}
            user={user}
            room={room}
          />
        ) : null}
      </CategoryWrap>
    </Container>
  );

  function onRateAnswer(playerId: string, isCorrect: boolean) {
    mutate(
      rateAnswer(
        room,
        room.activeQuestionId,
        playerId,
        isCorrect ? AnswerResult.CORRECT : AnswerResult.INCORRECT,
        isCorrect ? activeQuestion.value : 0,
      ),
    );
  }

  function formatQuestion(): Question {
    return {
      id: `${question.categoryId}-${question.questionId}`,
      answers: question.answers
        ? Object.values(question.answers)
            .sort((a, b) => a.playerId.localeCompare(b.playerId))
            .map((answer) => ({
              authorId: answer.playerId,
              authorName: room.players[answer.playerId].name,
              id: answer.playerId,
              answer: answer.answer,
              isCorrect:
                answer.result === AnswerResult.CORRECT ? true : answer.result === AnswerResult.INCORRECT ? false : null,
              points: answer.score,
              status: answer.isEditable ? AnswerStatus.CanAnswer : AnswerStatus.Answered,
            }))
        : [],
      points: activeQuestion.value,
      status: QuestionStatus.CanAnswer,
    };
  }
};
