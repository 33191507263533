import React from "react";

import { Destination, ErrorView } from "../../views/ErrorView";
import { LoadingView } from "../../views/LoadingView";
import { useParams } from "react-router-dom";
import { hasUnansweredQuestions, RoomState, useRoomData } from "../model/Room";
import { LobbyView } from "./LobbyViewQuiz";
import { QuestionView } from "./QuestionViewQuiz";
import { GameOverView } from "./GameOverViewQuiz";
import { MidRoundView } from "./MidRoundView";

interface RouteParams {
  roomId: string;
}

export const PlayView: React.FC = () => {
  //console.log("PlayView");
  const { roomId } = useParams<RouteParams>();

  const { isLoading, error, game, room } = useRoomData(roomId);

  if (isLoading) {
    return <LoadingView />;
  }

  if (error || !room || !game) {
    return <ErrorView error={error || new Error("Room not found")} destination={Destination.QUIZ} />;
  }

  if (room.state === RoomState.LOBBY) {
    return <LobbyView game={game} room={room} />;
  }

  if (room.activeQuestionId) {
    return <QuestionView room={room} game={game} question={room.questions[room.activeQuestionId]} />;
  }

  if (!hasUnansweredQuestions(room, game)) {
    return <GameOverView game={game} room={room} />;
  }

  return <MidRoundView game={game} room={room} />;
};
