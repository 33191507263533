import React, { useRef, useState } from "react";
import { Redirect } from "react-router";

import { Game, gameConverter, updateQuestion } from "../model/Game";
import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import { uploadImageToFirebase } from "../../services/uploadImageToFirebase";
import { useUser } from "../../hooks/useUser";

import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { LoadingView } from "../../views/LoadingView";
import { Container } from "../../components/Container";
import { Title, Text } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { StyledButton } from "../../components/Buttons";
import { areStringFieldsEmpty } from "../../util";

interface RouteParams {
  categoryId: string;
  questionId: string;
}

export const EditQuestionView: React.FC<{ game: Game }> = ({ game }) => {
  //console.log("EditQuestionView");
  const [questionUrl, setQuestionUrl] = useState<string>("");
  const [answerUrl, setAnswerUrl] = useState<string>("");
  const user = useUser();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const ref = doc(getFirestore(), "templates", game.id).withConverter(gameConverter);
  const { mutate, isLoading, isSuccess } = useFirestoreDocumentMutation(ref);
  const [, setIsViewLoading] = useState(false);
  const hiddenQuestionFileInput = React.useRef<HTMLInputElement>(null);
  const hiddenAnswerFileInput = React.useRef<HTMLInputElement>(null);
  const auth = getAuth();
  const { data: userData, isLoading: userIsLoading } = useAuthUser(["user"], auth);

  const categoryId = Number(params.categoryId);
  const questionId = Number(params.questionId);

  const category = game.categories[categoryId];
  const question = category?.questions[questionId];
  const isNew = areStringFieldsEmpty(question);

  const [questionText, setQuestionText] = useState(question.question);
  const [answerText, setAnswerText] = useState(question.answer);

  const questionImageRef = useRef("");
  const answerImageRef = useRef("");

  if (userIsLoading || isLoading) {
    return <LoadingView />;
  }

  if (!userData) {
    return <Redirect to="/" />;
  }

  if (!question) {
    return <Redirect to={`/jeopardy/game/${game.id}`} />;
  }

  const handleUpdateQuestion = async () => {
    await mutate(
      updateQuestion({
        game,
        categoryId,
        questionId,
        question: questionText,
        answer: answerText,
        questionImageUrl: questionImageRef.current || question.imageUrl,
        answerImageUrl: answerImageRef.current || question.answerImageUrl,
      }),
    );
  };

  const handleQuestionFileChanged = async (file: FileList | null) => {
    setIsViewLoading(true);
    if (file && file.length > 0) {
      const savedImageUrl = await uploadImageToFirebase(file[0], user);
      if (savedImageUrl) {
        questionImageRef.current = savedImageUrl;
        setQuestionUrl(savedImageUrl);
      }
    }
    setIsViewLoading(false);
  };

  const handleAnswerFileChanged = async (file: FileList | null) => {
    setIsViewLoading(true);
    if (file && file.length > 0) {
      const savedImageUrl = await uploadImageToFirebase(file[0], user);
      if (savedImageUrl) {
        answerImageRef.current = savedImageUrl;
        setAnswerUrl(savedImageUrl);
      }
    }
    setIsViewLoading(false);
  };

  const handleClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const currentQuestionUrl = questionUrl || question.imageUrl;
  const currentAnswerUrl = answerUrl || question.answerImageUrl;

  if (isSuccess) {
    return <Redirect to={`/jeopardy/game/${game.id}`} />;
  }

  return (
    <Container>
      <Title>
        Edit {category.name} • {question.value}
      </Title>
      <Text>Question</Text>
      <StyledInput
        value={questionText}
        required
        onChange={(e) => setQuestionText(e.target.value)}
        minLength={1}
        autoFocus
      ></StyledInput>
      <>
        <StyledButton variant="brown" onClick={() => handleClick(hiddenQuestionFileInput)}>
          <Text color="white">{"UPLOAD IMAGE FOR QUESTION"}</Text>
        </StyledButton>
        <input
          type="file"
          ref={hiddenQuestionFileInput}
          onChange={(e) => handleQuestionFileChanged(e.target.files)}
          style={{ display: "none" }}
        />
      </>
      {currentQuestionUrl && (
        <img className="question-image" src={`${currentQuestionUrl}`} alt={"question"} width={250} height={250} />
      )}
      <Title>ANSWER</Title>
      <StyledInput
        value={answerText}
        required
        onChange={(e) => setAnswerText(e.target.value)}
        minLength={1}
      ></StyledInput>
      <>
        <StyledButton variant="brown" onClick={() => handleClick(hiddenAnswerFileInput)}>
          <Text color="white">{"UPLOAD IMAGE FOR ANSWER"}</Text>
        </StyledButton>
        <input
          type="file"
          ref={hiddenAnswerFileInput}
          onChange={(e) => handleAnswerFileChanged(e.target.files)}
          style={{ display: "none" }}
        />
      </>
      {currentAnswerUrl && (
        <img className="question-image" src={`${currentAnswerUrl}`} alt={"question"} width={250} height={250} />
      )}
      <StyledButton onClick={() => handleUpdateQuestion()} variant="green" type="button" disabled={isLoading}>
        {isNew ? <Text color="white">{"CREATE QUESTION"}</Text> : <Text color="white">{"UPDATE QUESTION"}</Text>}
      </StyledButton>
      <StyledButton
        onClick={() => {
          history.replace(`/jeopardy/game/${game.id}`);
        }}
      >
        <Text color="white">{"BACK"}</Text>
      </StyledButton>
    </Container>
  );
};
