import styled from "styled-components";

export type directionVariant = "row" | "column";

export interface TeamsWrapProps {
  playerCount?: number;
  directionVariant?: directionVariant;
  gameOver?: boolean;
}

export interface QuestionNumberWrapProps {
  isAnswered?: boolean;
}

export const TeamsWrap = styled.div<TeamsWrapProps>`
  display: flex;
  flex-direction: ${(props) => props.directionVariant || "row"};
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: ${(props) => (props.gameOver ? "15vh" : "7vh")};
  background-color: black;
`;

export const TeamWrap = styled.div<{ gameOver?: boolean; compact?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.gameOver ? "space-around" : "center")};
  border-radius: 10px;
  border: 2px solid black;
  padding: 0.1em 1em 0;
  background: ${(props) => (props.gameOver ? "#0393fc" : "#4b831d")};
  text-shadow: 2px 2px 1px ${(props) => props.theme.text.shadow};
  font-size: ${(props) => (props.compact ? "0.5em" : "0.8em")};
`;

export const QuestionWrap = styled(TeamWrap)`
  margin-top: 0.2em;
  height: 7vh;
  width: 80vw;
  text-shadow: "";
  background: #0393fc;
`;

export const QuestionNumberWrap = styled(QuestionWrap)<QuestionNumberWrapProps>`
  opacity: ${(props) => (props.isAnswered ? "0.5" : "1")};
  width: 100%;
  border-radius: 2px;
`;
