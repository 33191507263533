import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentReference,
  doc,
  getFirestore,
  collection,
  CollectionReference,
  increment,
} from "firebase/firestore";
import { Category, Game, gameConverter, Question } from "./Game";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { generateRoomCode } from "../../services/room.service";
import { useFirestoreDocumentUpdateMutation } from "../../hooks/useFirestoreUpdateDocumentMutation";
import { GameType } from "../../constants";

export enum AnswerResult {
  PENDING = "PENDING",
  CORRECT = "CORRECT",
  INCORRECT = "INCORRECT",
}

export enum RoomState {
  LOBBY = "LOBBY",
  PLAYING = "PLAYING",
}

export interface GameSettings {
  allPlayersCanAnswer?: boolean;
}

export interface Room {
  id: string;
  code: string;
  creatorId: string;
  templatePath: string;
  players: Record<string, Player>;
  questions: Record<string, RoomQuestion>;
  settings: GameSettings;
  activeQuestionId: string | null;
  state: RoomState;
  type: GameType;
}

export interface Player {
  id: string;
  name: string;
  score: number;
}

export interface Answer {
  playerId: string;
  answer: string;
  isEditable: boolean;
  result: AnswerResult;
  score: number;
}

export interface RoomQuestion {
  id: string;
  categoryId: number;
  questionId: number;
  isAnswered: boolean;
  answers?: Record<string, Answer>;
}

export function getPlayersByScore(room: Room) {
  return Object.values(room.players).sort((a, b) => b.score - a.score);
}

function createSettings(): GameSettings {
  return {
    allPlayersCanAnswer: true,
  };
}

export function createRoom(game: Game, creatorId: string): Room {
  return {
    code: generateRoomCode(),
    id: "temp",
    creatorId: creatorId,
    // name,
    templatePath: `templates/${game.id}`,
    players: {},
    questions: {},
    settings: createSettings(),
    activeQuestionId: null,
    type: GameType.JEOPARDY,
    state: RoomState.LOBBY,
  };
}

export function startGame(): DocumentData {
  return {
    state: RoomState.PLAYING,
  };
}

export function pauseGame(): DocumentData {
  return {
    state: RoomState.LOBBY,
  };
}

export function joinRoom(playerName: string, playerId: string): DocumentData {
  return {
    [`players.${playerId}`]: {
      id: playerId,
      name: playerName,
      score: 0,
    },
  };
}

export function startQuestion(category: Category, question: Question): DocumentData {
  const id = `${category.id}-${question.id}`;

  return {
    [`questions.${id}.id`]: id,
    [`questions.${id}.categoryId`]: category.id,
    [`questions.${id}.questionId`]: question.id,
    [`questions.${id}.isAnswered`]: false,
    activeQuestionId: id,
  };
}

export function endQuestion(): DocumentData {
  return {
    activeQuestionId: null,
  };
}

export function finishQuestionAnswering(room: Room): DocumentData {
  const updates: DocumentData = {};

  if (room.activeQuestionId) {
    updates[`questions.${room.activeQuestionId}.isAnswered`] = true;
  }

  return updates;
}

export function cancelQuestion(): DocumentData {
  return {
    activeQuestionId: null,
  };
}

export function answerQuestion(room: Room, playerId: string, answer: string): DocumentData {
  return {
    [`questions.${room.activeQuestionId}.answers.${playerId}`]: {
      playerId,
      answer,
      isEditable: false,
      result: AnswerResult.PENDING,
      score: 0,
    },
  };
}

export function hasUnansweredQuestions(room: Room, game: Game): boolean {
  return game.categories.some((category) => {
    return category.questions.some((question) => {
      const id = `${category.id}-${question.id}`;

      return !!question.question && !room.questions[id]?.isAnswered;
    });
  });
}

export function rateAnswer(
  room: Room,
  questionId: string,
  playerId: string,
  result: AnswerResult,
  score: number,
): DocumentData {
  const currentMarkedScore = room.questions[questionId].answers?.[playerId].score || 0;

  return {
    [`questions.${questionId}.answers.${playerId}.result`]: result,
    [`questions.${questionId}.answers.${playerId}.score`]: score,
    [`players.${playerId}.score`]: increment(score - currentMarkedScore),
  };
}

export function getRoomDocRef(roomId: string): DocumentReference<Room> {
  return doc(getFirestore(), "rooms", roomId).withConverter(roomConverter);
}

export function getRoomCollectionRef(): CollectionReference<Room> {
  return collection(getFirestore(), "rooms").withConverter(roomConverter);
}

export function useUpdateRoom(roomId: string) {
  return useFirestoreDocumentUpdateMutation(doc(getFirestore(), "rooms", roomId).withConverter(roomConverter));
}

export function useRoomData(
  roomId: string,
):
  | { isLoading: true; error: null; game: null; room: null }
  | { isLoading: false; error: Error; game: null; room: null }
  | { isLoading: false; error: null; game: Game; room: Room } {
  const {
    data: room,
    error: roomError,
    isLoading: isRoomLoading,
  } = useFirestoreDocumentData(["room", roomId], getRoomDocRef(roomId), { subscribe: true });
  const {
    isLoading: isGameLoading,
    error: gameError,
    data: game,
  } = useFirestoreDocumentData(
    ["roomGame", roomId],
    room
      ? doc(getFirestore(), room?.templatePath).withConverter(gameConverter)
      : (undefined as unknown as DocumentReference<Game>),
    undefined,
    { enabled: !!room },
  );

  const isLoading = isGameLoading || isRoomLoading;

  if (isLoading) {
    return {
      isLoading,
      error: null,
      game: null,
      room: null,
    };
  }

  if (roomError || gameError || !room || !game) {
    return {
      isLoading: false,
      error: roomError || gameError || new Error("Missing data"),
      room: null,
      game: null,
    };
  }

  return {
    isLoading: false,
    error: null,
    game,
    room,
  };
}

export const roomConverter = {
  toFirestore(room: Room): DocumentData {
    return {
      ...room,
      templatePath: doc(getFirestore(), room.templatePath),
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Room {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      code: data.code,
      creatorId: data.creatorId,
      templatePath: data.templatePath.path,
      players: data.players,
      questions: data.questions,
      settings: data.settings,
      activeQuestionId: data.activeQuestionId,
      state: data.state,
      type: data.type,
    };
  },
};
