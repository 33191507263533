import React from "react";

import { TeamsWrap, TeamWrap } from "../../jeopardy/components/TeamWrap";
import { useHistory } from "react-router-dom";
import { getPlayersByScore, Room } from "../model/Room";
import { Game } from "../model/Game";
import { useRoomUser } from "../../hooks/useRoomUser";
import { useFirestoreDocumentUpdateMutation } from "../../hooks/useFirestoreUpdateDocumentMutation";
import { getSpectatorDocRef } from "../../services/spectator.service";
import { Text } from "../../components/Typography";
import { StyledHeaderButton } from "../../components/Buttons";
import { HeaderRowOne, HeaderRowTwo } from "../../components/StyledRows";
import { GameCell } from "../../jeopardy/components/GameCell";
import { GameGrid } from "../../jeopardy/components/GameGrid";
import { MainHeader } from "../../jeopardy/components/MainHeader";
import { Container } from "../../components/Container";

interface Props {
  game: Game;
  room: Room;
}

export const GameOverView: React.FC<Props> = ({ room }) => {
  //console.log("GameOverView");
  const history = useHistory();
  const user = useRoomUser(room);
  const players = getPlayersByScore(room);

  const { mutate: updateSpectator } = useFirestoreDocumentUpdateMutation(getSpectatorDocRef(user.uid));

  async function handleSpectatorLeaveRoom() {
    await updateSpectator({
      roomId: "",
    });
    history.replace("/spectate");
  }

  return (
    <Container>
      <MainHeader directionVariant="column">
        <HeaderRowOne justifyContentVariant="flex-end">
          <StyledHeaderButton onClick={() => (user.isSpectator ? handleSpectatorLeaveRoom() : history.push("/"))}>
            <Text color="white">{"Back"}</Text>
          </StyledHeaderButton>
        </HeaderRowOne>
        <HeaderRowTwo>
          <Text fontSize={2} color="white">
            {"GAME OVER"}
          </Text>
        </HeaderRowTwo>
      </MainHeader>
      <GameGrid>
        <GameCell>
          <TeamsWrap gameOver directionVariant="column">
            {players.length > 1 && players[0].score === players[1].score ? (
              <TeamWrap gameOver>
                <Text color="white">{"Uh oh.. it's a draw!"}</Text>
              </TeamWrap>
            ) : (
              <TeamWrap gameOver>
                <Text fontSize={1.5} color="white">{`${players[0].name} is victorious!`}</Text>
              </TeamWrap>
            )}

            {players.map((player) => (
              <TeamWrap gameOver key={player.id}>
                <Text color="white">{player.name}</Text>
                <Text fontSize={1.5} color="white">
                  {player.score} points
                </Text>
              </TeamWrap>
            ))}
          </TeamsWrap>
        </GameCell>
      </GameGrid>
    </Container>
  );
};
