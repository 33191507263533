import React from "react";
import styled from "styled-components";

import { LoadingIcon } from "../components/Icon";
import { View } from "../components/View";

const LoadingWrap = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const LoadingView: React.SFC = () => (
  <View>
    <LoadingWrap>
      <LoadingIcon />
    </LoadingWrap>
  </View>
);
