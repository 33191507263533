import styled from "styled-components";

export interface GameGridProps {
  columnCount?: number;
  withPlayers?: boolean;
  isSpectator?: boolean;
  questionClosed?: boolean;
}

export const GameGrid = styled.div<GameGridProps>`
  display: grid;
  position: relative;
  width: 100vw;
  height: ${(props) =>
    props.withPlayers ? (props.isSpectator ? "93vh" : "83vh") : props.questionClosed ? "50vh" : "90vh"};
  grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
`;
