import React, { useState } from "react";

import { HeaderRowOne, HeaderRowTwo } from "../../components/StyledRows";

import { Redirect, useHistory } from "react-router-dom";
import { useFirestoreCollectionMutation, useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";
import { collection, doc, getFirestore } from "firebase/firestore";
import { useUser } from "../../hooks/useUser";
import { StyledButton, StyledHeaderButton } from "../../components/Buttons";
import { Text } from "../../components/Typography";
import { LoadingView } from "../../views/LoadingView";
import { Game } from "../model/Game";
import { Container } from "../../components/Container";
import { createRoom, getRoomCollectionRef } from "../model/Room";
import { MainHeader } from "../../jeopardy/components/MainHeader";
import { QuestionWrap } from "../../jeopardy/components/TeamWrap";

interface Props {
  game: Game;
}

export const EditGameView: React.FC<Props> = ({ game }) => {
  //console.log("EditGameView");
  const history = useHistory();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { mutate, data } = useFirestoreCollectionMutation(getRoomCollectionRef());
  const ref = doc(collection(getFirestore(), "templates"), game.id);
  const { mutate: deleteGame } = useFirestoreDocumentDeletion(ref);

  function createRoomHandler() {
    setLoading(true);

    mutate(createRoom(game, user.uid));
  }

  function deleteHandler() {
    setLoading(true);

    deleteGame();
    history.replace("/");
  }

  if (data) {
    return <Redirect to={`/quiz/room/${data.id}`} />;
  }

  if (loading) {
    return <LoadingView />;
  }

  return (
    <Container>
      <MainHeader directionVariant="column">
        <HeaderRowOne justifyContentVariant="space-evenly">
          <StyledHeaderButton onClick={() => history.replace(`/templates/${game.type}`)}>
            <Text color="white" fontSize={1.1}>
              {"Back"}
            </Text>
          </StyledHeaderButton>
          <StyledHeaderButton onClick={deleteHandler}>
            <Text color="white" fontSize={1.1}>
              {"Delete quiz"}
            </Text>
          </StyledHeaderButton>
          <StyledHeaderButton onClick={createRoomHandler}>
            <Text color="white" fontSize={1.1}>
              {"Start quiz"}
            </Text>
          </StyledHeaderButton>
        </HeaderRowOne>
        <HeaderRowTwo>
          <Text color="white">{game.name.toUpperCase()}</Text>
        </HeaderRowTwo>
      </MainHeader>

      <Text fontSize={2}>Questions</Text>
      {game.questions.map((question, index) => (
        <QuestionWrap key={question.id} onClick={() => history.push(`/quiz/game/${game.id}/edit-question/${index}`)}>
          <Text fontSize={1.3} color="white">
            {question.question}
          </Text>
        </QuestionWrap>
      ))}
      <StyledButton
        variant="green"
        onClick={() => history.push(`/quiz/game/${game.id}/edit-question/${game.questions.length}`)}
      >
        <Text color="white">{"Add Questions"}</Text>
      </StyledButton>
    </Container>
  );
};
