import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const app = initializeApp({
  apiKey: "AIzaSyANRGqP-ZcLnXZcaBM7EDaLYVsieAunqQU",
  authDomain: "quizify-330709.firebaseapp.com",
  projectId: "quizify-330709",
  storageBucket: "quizify-330709.appspot.com",
  messagingSenderId: "282581703133",
  appId: "1:282581703133:web:54b78866d891ad6f58be34",
});

export const db = getFirestore(app);
