import React, { useState } from "react";

import { query, where, getDocs, limit, updateDoc } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import { useLogout, useUser } from "../../hooks/useUser";
import { getRoomCollectionRef, getRoomDocRef, joinRoom } from "../../jeopardy/model/Room";
import { Container } from "../../components/Container";
import { StyledButton } from "../../components/Buttons";
import { TextLabel, Title, Text } from "../../components/Typography";
import { StyledInput } from "../../components/Form";
import { GameType } from "../../constants";
import assertNever from "../../services/assertNever";

const LandingView: React.FC = () => {
  const { search } = useLocation();
  const user = useUser();
  const { logout } = useLogout();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState(() => new URLSearchParams(search).get("code") || "");
  const [error, setError] = useState("");

  function handleGameModuleSelectionOnJoin(type: GameType, roomId: string) {
    switch (type) {
      case GameType.JEOPARDY:
        history.push(`/jeopardy/room/${roomId}`);
        break;
      case GameType.QUIZ:
        history.push(`/quiz/room/${roomId}`);
        break;
      default:
        return assertNever(type);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);

    setError("");

    const ref = query(getRoomCollectionRef(), where("code", "==", code), limit(1));

    const roomsQuery = await getDocs(ref);

    if (roomsQuery.empty) {
      setIsLoading(false);
      setError("Room not found");
      return;
    }

    const roomSnapshot = roomsQuery.docs[0];

    const type = roomSnapshot.data().type;

    await updateDoc(getRoomDocRef(roomSnapshot.id), joinRoom(name, user.uid));

    handleGameModuleSelectionOnJoin(type, roomSnapshot.id);
  }

  return (
    <Container as="form" onSubmit={handleSubmit} variant="center">
      <Title>Quizify</Title>
      <TextLabel>NICKNAME</TextLabel>
      <StyledInput value={name} required onChange={(e) => setName(e.target.value)} minLength={4} autoFocus />
      <TextLabel>ROOM CODE</TextLabel>
      <StyledInput
        value={code}
        required
        type={"tel"}
        onChange={(e) => setCode(e.target.value)}
        minLength={4}
        maxLength={4}
      />
      {error && <p>{error}</p>}
      <StyledButton variant="brown" type="submit" disabled={isLoading}>
        {isLoading ? <Text color="white">{"Joining..."}</Text> : <Text color="white">{"JOIN ROOM"}</Text>}
      </StyledButton>

      {user.isAnonymous ? null : (
        <>
          <StyledButton
            variant="green"
            onClick={() => {
              history.push(`/templates/${GameType.JEOPARDY}`);
            }}
          >
            <Text color="white">{"JEOPARDY"}</Text>
          </StyledButton>
          <StyledButton
            variant="green"
            onClick={() => {
              history.push(`/templates/${GameType.QUIZ}`);
            }}
          >
            <Text color="white">{"QUIZ"}</Text>
          </StyledButton>
        </>
      )}

      <StyledButton onClick={() => logout()} type="button">
        <Text color="white">{"LOGOUT"}</Text>
      </StyledButton>
    </Container>
  );
};

export default LandingView;
